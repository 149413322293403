import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthContext from '../context/AuthContext';
import '../App.css';

const SQLPractice = () => {
    const { authTokens, logoutUser } = useContext(AuthContext);
    const [questionData, setQuestionData] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [correctCount, setCorrectCount] = useState(0);
    const [questionCount, setQuestionCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const fetchQuestion = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sql-practice`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(authTokens.access)
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Response data:', data);  // Log the entire response data

                // Parse the JSON string in the content field
                const parsedContent = JSON.parse(data.content);
                const options = shuffleArray([
                    { text: parsedContent.right_answer, isCorrect: true },
                    { text: parsedContent.wrong_answer, isCorrect: false }
                ]);
                const formattedQuestionData = {
                    query: parsedContent.question,
                    options: options,
                    explanation: parsedContent.explanation
                };
                setQuestionData(formattedQuestionData);
            } else if (response.status === 401) {
                logoutUser();
            } else {
                console.error('Error fetching the question:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching the question:', error);
        } finally {
            setIsLoading(false);
        }
    }, [authTokens, logoutUser]);

    useEffect(() => {
        fetchQuestion();
    }, [fetchQuestion]);

    const handleOptionClick = (isCorrect) => {
        setSelectedOption(isCorrect);
        setQuestionCount(prevCount => prevCount + 1);
        if (isCorrect) {
            setIsCorrect(true);
            setCorrectCount(prevCount => prevCount + 1);
        } else {
            setIsCorrect(false);
        }
    };

    const handleNextQuestion = () => {
        setSelectedOption(null);
        setIsCorrect(null);
        fetchQuestion();
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!questionData) {
        return <p>Loading question data...</p>;
    }

    const correctPercentage = questionCount > 0 ? ((correctCount / questionCount) * 100).toFixed(2) : 0;

    return (
        <div className="sql-practice">
            <h1>SQL Practice</h1>
            <div className="score">
                <p>Correct answers: {correctCount} / {questionCount} ({correctPercentage}%)</p>
            </div>
            <div className="explanation">
                <p>{questionData.query}</p>
            </div>
            <div className="options">
                {questionData.options.map((option, index) => (
                    <div
                        key={index}
                        className={`option ${selectedOption === option.isCorrect ? (isCorrect ? 'correct' : 'incorrect') : ''}`}
                        onClick={() => handleOptionClick(option.isCorrect)}
                        style={{ cursor: selectedOption !== null ? 'not-allowed' : 'pointer' }} // Change cursor style after selection
                    >
                        <pre>{option.text}</pre>
                    </div>
                ))}
            </div>
            {selectedOption !== null && (
                <div className="feedback">
                    {isCorrect ? <p>Correct!</p> : <p>Incorrect. Try again.</p>}
                    <p>{questionData.explanation}</p>
                    <button onClick={handleNextQuestion}>Next Question</button>
                </div>
            )}
        </div>
    );
};

export default SQLPractice;
