import React from 'react';
import '../App.css'; // Ensure this is the correct path to your CSS file

const Footer = () => {
   
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-text">
                    <p>&copy; TheGreatestBen 1874. No rights reserved.</p>
                    <p>Contact: chopchop@sushi.com</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
