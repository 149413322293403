import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import '../App.css'; // Ensure this is the correct path to your CSS file

const LoginPage = () => {
    let { loginUser } = useContext(AuthContext);

    return (
        <div className="center">
            <form onSubmit={loginUser} className="login-form">
                <input type="text" name="username" placeholder="Enter username" className="input-field" />
                <input type="password" name="password" placeholder="Enter password" className="input-field" />
                <input type="submit" className="submit-button" />
                <p className="signup-link">Don't have an account? <a href="/register">Sign Up</a></p>
            </form>
        </div>
    );
}

export default LoginPage;
