import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SQLPractice from './pages/SQLPractice'; // Import the new page
import Header from './components/Header';
import Footer from './components/Footer';

import PrivateRoute from './utils/PrivateRoute';

function App() {
    return (
        <div className="App">
            <Router>
                <AuthProvider>
                    <Header/>
                    <Routes>
                        <Route path="/" element={
                            <PrivateRoute>
                                <HomePage/>
                            </PrivateRoute>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/sql-practice" element={<SQLPractice/>}/> {/* Add new route */}
                    </Routes>
                    <Footer /> {/* Include the Footer component here */}
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
