import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import '../App.css'; // Ensure this is the correct path to your CSS file

const Header = () => {
    const { user, logoutUser } = useContext(AuthContext);
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Toggle the dark mode
    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    // Load the saved theme from local storage
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            setIsDarkMode(true);
        }
    }, []);

    // Apply the dark mode class to the body and save the preference in local storage
    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
            localStorage.setItem('theme', 'dark');
        } else {
            document.body.classList.remove('dark-mode');
            localStorage.setItem('theme', 'light');
        }
    }, [isDarkMode]);

    return (
        <div className="header">
            <div className="nav-links">
                <Link to="/">Home</Link>
                {user && (
                    <>
                        <p onClick={logoutUser}>Logout</p>
                        <Link to="/sql-practice">SQLPractice</Link>
                    </>
                )}
            </div>
            {user && (
                <div className="user-info">
                    <p className="welcome">Hello {user.username}!</p>
                    <button className="dark-mode-toggle" onClick={toggleDarkMode}>
                        {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Header;
