import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthContext from '../context/AuthContext';
import '../App.css'; 

const HomePage = () => {
    const { authTokens, logoutUser: originalLogoutUser } = useContext(AuthContext);
    const [profile, setProfile] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const logoutUser = useCallback(() => {
        originalLogoutUser();
    }, [originalLogoutUser]);

    const getProfile = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            }
        });
        let data = await response.json();
        console.log(data);
        if (response.status === 200) {
            setProfile(data);
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
        }
    }, [authTokens, logoutUser]);

    const handleSearch = async (event) => {
        event.preventDefault();
        setLoading(true);  // Start loading
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search?query=${searchQuery}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            }
        });
        let data = await response.json();
        console.log(data);
        setLoading(false);  // Stop loading
        if (response.status === 200) {
            setSearchResult(data);
        } else if (response.statusText === 'Unauthorized') {
            logoutUser();
        }
    };

    useEffect(() => {
        getProfile();
    }, [getProfile]);

    return (
        <div>
            <form onSubmit={handleSearch} className="search-form">
                <input 
                    type="text" 
                    value={searchQuery} 
                    onChange={(e) => setSearchQuery(e.target.value)} 
                    placeholder="Search for a company to get its valuation"
                    className="search-input"
                />
                <button type="submit" className="search-button">Search</button>
            </form>

            <div>
                {loading && <p className="loading">Loading...</p>}  {/* Render loading indicator */}
                {searchResult && !loading && (
                    <div className="search-result">
                        <h2>{searchResult.company} Net Asset Value:</h2>
                        <p>{searchResult.net_asset_value}</p>
                    </div>
                )}
            </div>
            <p>You are logged in to the homepage!</p>
            <p>Name: {profile.first_name} {profile.last_name}</p>
            <p>Email: {profile.email}</p>
        </div>
    );
};

export default HomePage;
